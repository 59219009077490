<template>
	<div class="affirmgoods">
		<frame :top1="top1" :top2="top2">
			<search @search='search'></search>
			<lists :titles='titles' :flag='flag' :contentC='datas' @add='refresh()' @del='refresh()'></lists>
			<page :num='num'></page>
		</frame>
	</div>
</template>

<script>
	import frame from '../public/Frame.vue';
	import lists from './componAffirm/List.vue';
	import search from './componAffirm/Search.vue';
	import page from '../commodity/componList/Page.vue';
	export default {
		name: 'affirmgoods',
		data() {
			return {
				top1: '5-2',
				top2: ['5'],
				flag: 1,
				titles: ['订单编号', '提交时间', '收货人', '订单金额', '支付状态', '自动确认收货时间', '订单收货时间', '操作'],
				datas: null,
				num: 100
			}
		},
		components: {
			frame,
			lists,
			search,
			page
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.$request.receivingList({
				token,
				name: '',
				order: '',
				page: 1,
				limit: 10,
				time: '',
				type: this.$storage.getLocal('type')
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data.data
					this.num = res.data.cunt
				}
			})
		},
		methods: {
			refresh() {
				let token = this.$storage.getLocal('token');
				this.$request.receivingList({
					token,
					name: '',
					order: '',
					page: 1,
					limit: 10,
					time: '',
					type: this.$storage.getLocal('type')
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			},
			cut(item) {
				
			},
			search(item) {
				let newTime = ''
				if(item.date){
					newTime = item.date.getFullYear() + '-' + (item.date.getMonth() + 1) + '-' + item.date.getDate()
				}
				
				let token = this.$storage.getLocal('token');
				this.$request.receivingList({
					token,
					name: item.phoneName,
					order: item.goodsName,
					page: 1,
					limit: 10,
					time: newTime,
					type: this.$storage.getLocal('type')
				}).then(res => {
					if (res.code == 0) {
						this.datas = res.data.data
						this.num = res.data.cunt
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
