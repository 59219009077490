<template>
	<div class="content">
		<div class="liubai">
			<Icon type="ios-search" />筛选查询
		</div>
		<div class="search">
			<div class="input">
				<div class='text'>请输入：</div>
				<Input v-model="goodsName" placeholder="请输入编号/货号" clearable style="width: 200px" />
			</div>
			<div class="input">
				<div class='text'>请输入：</div>
				<Input v-model="phoneName" placeholder="请输入手机号/姓名" clearable style="width: 200px" />
			</div>
			<div class="input">
				<Form :model="formItem" :label-width="80">
					<FormItem label="选择时间">
						<Row>
							<Col span="13">
							<DatePicker type="date" placeholder="请选择时间" v-model="formItem.date"></DatePicker>
							</Col>
						</Row>
					</FormItem>
				</Form>
			</div>



			<div class="input">
				<Button type="success" @click='search()'>搜索</Button>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'search',
		data() {
			return {
				goodsName: '',
				phoneName: '',
				formItem: {
					date: '',
				},
			}
		},
		created() {
			let token = this.$storage.getLocal('token');

		},
		methods: {
			search() {
				this.$emit('search', {
					goodsName: this.goodsName,
					phoneName: this.phoneName,
					date: this.formItem.date
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.search {
		padding-left: 200px;
		display: flex;
		/* justify-content: center; */
		text-align: left;
	}

	.input {
		/* width: 80%; */
		/* margin: 0 auto; */
		display: flex;
		justify-content: space-between;
		line-height: 30px;
		margin-left: 50px;
		padding: 10px 0 10px 0;
	}

	.liubai {
		width: 100%;
		height: 40px;
		background-color: #F3F3F3;
		font-size: 15px;
		text-align: left;
		padding-left: 220px;
		line-height: 40px;
	}
</style>
