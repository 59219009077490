<template>
	<div class="list">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		
		<Modal v-model="modal1" title="商品审核" @on-ok="ok">
			<div v-if="SHlist.length>0">
				<table>
					<tr>
						<td class='titNa'>审核时间</td>
						<td class='titNa'>审核人员</td>
						<td class='titNa'>审核结果</td>
						<td class='titNa'>反馈详情</td>
					</tr>
					<tr v-for="item9,index9 in SHlist" :key='index9'>
						<td class="conBo">{{item9.ctime}}</td>
						<td class="conBo">{{item9.admin_name}}</td>
						<td class="conBo">{{item9.status==1?'通过':'未通过'}}</td>
						<td class="conBo">{{item9.content==''?'无':item9.content}}</td>
					</tr>
				</table>
				<Button v-if='state == 2' type="success" @click='repetition()'>重新提交审核</Button>
			</div>
			<div v-if="SHlist.length<=0">
				<table>
					<tr>
						<td class='titNa'>审核时间</td>
						<td class='titNa'>审核人员</td>
						<td class='titNa'>审核结果</td>
						<td class='titNa'>反馈详情</td>
					</tr>
					<tr>
						<td class="conBo">暂无审核数据</td>
						<td class="conBo">暂无审核数据</td>
						<td class="conBo">暂无审核数据</td>
						<td class="conBo">暂无审核数据</td>
					</tr>
				</table>
			</div>
		</Modal>
		
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">商品列表</div>
				<div class="title">
					<slot></slot>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2' v-if="flag==1">
									<td>
										{{item2.order_sn}}
									</td>
									<td>{{item2.ctime}}</td>
									<td>
										{{item2.receiving_user}}
									</td>
									<td>¥{{item2.order_money}}</td>
									<td>{{item2.order_status_name}}</td>
									<td>{{item2.end_at}}天</td>
									<td>{{item2.end_at_time}}</td>
									<td class="oper">
										<!-- <div class="stte" @click="examine(item2.gid)">查看</div> -->
										<div class="affirm" @click="affirm(item2.orderid)">确认收货</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			},
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				imgUrl: '',
				modal1: false,
				id: null,
				SHlist: [],
				state:null
			}
		},
		created() {
			this.imgUrl = this.$request.imgUrl2()+'uploads/';
		},
		components: {

		},
		methods: {
			audit(id) { //审核
				let token = this.$storage.getLocal('token');
				this.$request.selGoodsReviewDataList({token,id}).then(res=>{
					if(res.code == 0){
						this.SHlist = res.data.list
						this.modal1 = true;
						this.id = id;
						this.state = res.data.is_gtype
					}else{
						this.$notify({
							title: '警告',
							message: res.msg,
							type: 'warning'
						})
					}
				})
			},
			ok() {
				console.log('ok');
			},
			repetition(){
				let token = this.$storage.getLocal('token');
				this.$request.setGoodsReviewType({token,id:this.id}).then(res=>{
					if(res.code == 0){
						this.$notify({
							title: '成功',
							message: '已经重新提交审核',
							type: 'success'
						});
						this.modal1 = false;
						this.$emit("del", 'ok');
					}else{
						this.$notify({
							title: '警告',
							message: res.msg,
							type: 'warning'
						})
					}
				})
			},
			
			
			start(id,start) {
				let token = this.$storage.getLocal('token');
				this.$request.goodsIsGrounding({
					token,
					id,
					grounding: start==2 ? 1 : 2,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
						this.$emit("del", 'ok');
					} else {
						this.$notify({
							title: '警告',
							message: '失败',
							type: 'warning'
						})
					}
				})
			},
			affirm(id) { //确认收货
				this.$confirm('此操作将确认收货该订单,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.receivingSet({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("add", 'ok');
						} else {
							this.$emit("add", 'ok');
							this.$notify({
								title: '失败',
								message: res.msg,
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			},
			log(id) { //日志
				console.log(id)
			},
			compile(id) { //编辑
				this.$router.push("/addCommodity?id="+id,function(){},function(){})
			}
		}
	}
</script>

<style scoped="scoped">
	img {
		width: 20px;
	}
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}
	.titNa{
		text-align: center;
	}
	.conBo{
		text-align: center;
	}
	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.shenhe{
		color: #42B983;
	}
</style>
