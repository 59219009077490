<template>
	<div class="page">
		<Page :total="num" show-elevator @on-change='pagebtn'/>
	</div>
</template>

<script>
	export default{
		name:'page',
		data(){
			return {
				current:1
			}
		},
		props:{
			num:Number
		},
		methods:{
			pagebtn(val){
				console.log(`当前页: ${val}`)
			}
		}
	}
</script>

<style>
</style>
